/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $('.nav .dropdown').hover(function() {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
 
		}, function() {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
 
		});
 
		$('.nav .dropdown > a').click(function(){
			location.href = this.href;
		});
        /*
		 * Responsive menu by Trinos
		 */
		   
		// On click on hamburger icon show the menu
		jQuery('.show-menu').click(function() {
			$('#responsive-menu').show();
		  $('#responsive-menu').animate({
		    left: '0'
		  }, 400);
		  $('body').addClass('non-scrollable');
		  $('.show-menu').css("display", "none");
		  $( "<div class='childclick'><i class='fa fa-plus'></i></div><div class='childhide'><i class='fa fa-minus'></i></div>" ).insertAfter( ".menu-item-has-children > a" );
		}); 
		// On click on the X icon hide menu
		jQuery('.hide-menu').click(function() {
		  $('#responsive-menu').animate({
		    left: '100%'
		  }, 400).hide();
		  $('body').removeClass('non-scrollable');
		  $('.show-menu').css("display", "inline-block");
		  $( ".childclick" ).remove();
		  $( ".childhide" ).remove();
		});
		// Add show class to menu item to expand submenu
		$(document).on('click', '.childclick', function() {
		    $(this).parent().toggleClass('show');
		});
		// Hide show class of menu item when closing submenu
		$(document).on('click', '.childhide', function() {
		    $(this).parent().toggleClass('show');
		});
		
		$(document.body).on('update_checkout', function(e){
			//e.preventDefault();
			//e.stopPropagation();
			e.stopImmediatePropagation();
			//console.log(e);
		});
		// Product page actions
		$(document).on('click','#afhaalbox',function(){	
			if (this.checked) {
				$('.prijs-korting').toggle(500);
				if ($('.prijs-bezorgen').is(":visible")){ 
					$('.prijs-bezorgen').toggle(500);
				}
				if ($('.prijs-opbouwen').is(":visible")){ 
					$('.prijs-opbouwen').toggle(500);
				}
				if ($('.prijs-begeleiden').is(":visible")){ 
					$('.prijs-begeleiden').toggle(500);
				}
				$( "#bezorgbox" ).prop( "checked", false );
				$( "#opbouwbox" ).prop( "checked", false );
				$( "#begeleidbox" ).prop( "checked", false );
			}
			else {
				$( "#bezorgbox" ).prop( "checked", true );
				$('.prijs-korting').toggle(500);
				$('.prijs-bezorgen').toggle(500);
			}
		});
		$(document).on('click','#bezorgbox',function(){	
			if (this.checked) {
				$( "#afhaalbox" ).prop( "checked", false );
				$('.prijs-bezorgen').toggle(500);
				if ($('.prijs-korting').is(":visible")){ 
					$('.prijs-korting').toggle(500);
				}
			}
			else {
				if ($('.prijs-bezorgen').is(":visible")){ 
					$('.prijs-bezorgen').toggle(500);
				}
				if ($('.prijs-opbouwen').is(":visible")){ 
					$('.prijs-opbouwen').toggle(500);
				}
				if ($('.prijs-begeleiden').is(":visible")){ 
					$('.prijs-begeleiden').toggle(500);
				}
				$('.prijs-korting').toggle(500);
				$('#afhaalbox').prop("checked", true);
				$('#opbouwbox').prop("checked", false);
				$('#begeleidbox').prop("checked", false);
			}
		}); 
		$(document).on('click','#opbouwbox',function(){	
			if (this.checked) {
				$( "#afhaalbox" ).prop( "checked", false );
				if ($('.prijs-bezorgen').is(":visible")){ 
					$('.prijs-bezorgen').toggle(500);
				}
				if ($('.prijs-korting').is(":visible")){ 
					$('.prijs-korting').toggle(500);
				}
				$('.prijs-opbouwen').toggle(500);
				$('#bezorgbox').prop("checked", true);
			}
			else {
				
				$('.prijs-bezorgen').toggle(500);
				if ($('.prijs-opbouwen').is(":visible")){ 
					$('.prijs-opbouwen').toggle(500);
				}
				if ($('.prijs-begeleiden').is(":visible")){ 
					$('.prijs-begeleiden').toggle(500);
				}
				$('#begeleidbox').prop("checked", false);
			}
			
		});
		$(document).on('click','#begeleidbox',function(){	 
			if (this.checked) {
				$( "#afhaalbox" ).prop( "checked", false );
				if ($('.prijs-bezorgen').is(":visible")){ 
					$('.prijs-bezorgen').toggle(500);
				}
				if ($('.prijs-korting').is(":visible")){ 
					$('.prijs-korting').toggle(500);
				}
				if ($('.prijs-opbouwen').is(":visible")){ 
					$('.prijs-opbouwen').toggle(500);
				}
				$('.prijs-begeleiden').toggle(500);
				$('#bezorgbox').prop("checked", true);
				$('#opbouwbox').prop("checked", true);
			}
			else {
				$('.prijs-opbouwen').toggle(500);
				if ($('.prijs-begeleiden').is(":visible")){ 
					$('.prijs-begeleiden').toggle(500);
				}
			}
		}); 
		
		//shopping cart actions
		$(document).on('click','.afhaalboxcart',function(){	
			if (this.checked) {
				$('.afhaalboxcart').prop("checked", true);
				$('.bezorgboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", false);
				$('.begeleidboxcart').prop("checked", false);
			}
			else {
				$('.bezorgboxcart').prop("checked", true);
			}
			
			
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).on('click','.bezorgboxcart',function(){	
			
			
			if (/Edge\/12./i.test(navigator.userAgent) || /chrom(e|ium)/.test(navigator.userAgent.toLowerCase()) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent)){
				if ($(this).attr('checked')) {
					
			
	

					$('.afhaalboxcart').prop("checked", false);
				}
				else {
					$('.afhaalboxcart').prop("checked", true);
				}
			  
			}
			
			if ($(this).attr('checked')) {
				$('.bezorgboxcart').prop("checked", true);
				$('.afhaalboxcart').prop("checked", false);
			}
			else {
				$('.bezorgboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", false);
				$('.begeleidboxcart').prop("checked", false);
				$('.afhaalboxcart').prop("checked", true);
			}
			
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).ready(function(){
			$( 'div.woocommerce > form input[name="update_cart"]' ).prop( 'disabled', false );	
		});
		$( document ).ajaxSuccess(function() {
			$( 'div.woocommerce > form input[name="update_cart"]' ).prop( 'disabled', false );

		}); 
			
		$(document).on('click','.opbouwboxcart',function(){	
			
			if (/Edge\/12./i.test(navigator.userAgent) || /chrom(e|ium)/.test(navigator.userAgent.toLowerCase()) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent)){
				console.log('test4');
				if ($(this).attr('checked')) {
					$('.bezorgboxcart').prop("checked", true);
					$('.afhaalboxcart').prop("checked", false); 
				}
				else {
					$(this).closest('tr').find('.begeleidboxcart').prop("checked", false);
				}
			}
			else {
				if (this.checked) {
					
					$('.bezorgboxcart').prop("checked", true);
					$('.afhaalboxcart').prop("checked", false);
				}
				else {
					$(this).closest('tr').find('.begeleidboxcart').prop("checked", false); 
						
				}
			}
			
			$( 'div.woocommerce > form input[name="update_cart"]' ).trigger("click");
		});	
//		$('.opbouwboxcart').click(function(){
//			
//			if (/Edge\/12./i.test(navigator.userAgent) || /chrom(e|ium)/.test(navigator.userAgent.toLowerCase()) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent)){
//				
//				
//				if ($(this).attr('checked')) {
//					$('.opbouwboxcart').prop("checked", true);
//					$('.bezorgboxcart').prop("checked", true);
//					$('.afhaalboxcart').prop("checked", false);
//					console.log('check');
//				}
//				else {
//					console.log('not');
//					$(this).closest('tr').find('.begeleidboxcart').prop("checked", false); 
//					$(this).prop("checked", true);
//				}
//			  
//			} 
//			
//			
//				if (this.checked) {
//					$('.bezorgboxcart').prop("checked", true);
//					$('.afhaalboxcart').prop("checked", false);
//				}
//				else {
//					$(this).closest('tr').find('.begeleidboxcart').prop("checked", false); 
//						
//				}
//	
//			
//			$('.update_cart-btn').delay( 300 ).trigger("click");
//		}); 
		$(document).on('click','.begeleidboxcart',function(){	
			if (/Edge\/12./i.test(navigator.userAgent) || /chrom(e|ium)/.test(navigator.userAgent.toLowerCase()) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent)){
				if ($(this).attr('checked')) {
					
					
				}
				else {
					
				}
			  
			}
			
			
			if (this.checked) {
				$('.bezorgboxcart').prop("checked", true);
				$(this).closest('tr').find('.opbouwboxcart').prop("checked", true);
				$('.afhaalboxcart').prop("checked", false); 
			}
			else {
				
			}
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).on('click','#datebtn',function(){	
			$('.update_cart-btn').trigger("click");
		});
		$(document).on('click','#distancebtn',function(){	

			$('.update_cart-btn').trigger("click");
		});
		$(document).on('click','.verzekeringbox',function(){	
			$('.update_cart-btn').trigger("click");
		});
		$(document).on('click','th.product-afhalen',function(){				
			if ($('.afhaalboxcart').attr('checked')) {
				$('.afhaalboxcart').prop("checked", false);
				$('.bezorgboxcart').prop("checked", true);
			}
			else {
				$('.afhaalboxcart').prop("checked", true);
				$('.bezorgboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", false);
				$('.begeleidboxcart').prop("checked", false);
				
			}
			
			
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).on('click','th.product-bezorgen',function(){	
			
			if ($('.bezorgboxcart').attr('checked')) {
				$('.bezorgboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", false);
				$('.begeleidboxcart').prop("checked", false);
				$('.afhaalboxcart').prop("checked", true);
			}
			else {
				$('.bezorgboxcart').prop("checked", true);
				$('.afhaalboxcart').prop("checked", false);
				
			}
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).on('click','th.product-opbouwen',function(){	
			
			if ($('.opbouwboxcart').attr('checked')) {
				$('.begeleidboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", false);
			}
			else {
				$('.bezorgboxcart').prop("checked", true);
				$('.afhaalboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", true);
			}
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});	
		$(document).on('click','th.product-begeleiden',function(){		
			
			if ($('.begeleidboxcart').attr('checked')) {
				$('.begeleidboxcart').prop("checked", false);
			}
			else {
				$('.bezorgboxcart').prop("checked", true);
				$('.afhaalboxcart').prop("checked", false);
				$('.opbouwboxcart').prop("checked", true);
				$('.begeleidboxcart').prop("checked", true);
			}
			
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		$(document).on('click','.meerdaagsebox',function(){				 
			$('.eind').toggle(500);
			$('.meerdere').toggle(500);
			$('.dag').toggle(500);	
			$('td.korting').toggle(500);
			$('.update_cart-btn').delay( 300 ).trigger("click");
		});
		
		$(document).on('click','.pika-single .pika-button',function(){		
            $('.update_cart-btn').trigger("click");
        });
        $(document).on('change','#calc_shipping_postcode',function(){	
            $('.update_cart-btn').trigger("click");
        });
        $(document).on('change','.quantity .qty',function(){	
            $('.update_cart-btn').trigger("click");
        
        });
        
		// Tooltip
		$( function(){
		    var targets = $( '[rel~=tooltip]' ),
		        target  = false,
		        tooltip = false,
		        title   = false;
		 
		    targets.bind( 'mouseenter', function()
		    {
		        target  = $( this );
		        tip     = target.attr( 'title' );
		        tooltip = $( '<div id="tooltip"></div>' );
		 
		        if( !tip || tip === '' ){
		            return false;
				}
		        target.removeAttr( 'title' );
		        tooltip.css( 'opacity', 0 )
		               .html( tip )
		               .appendTo( 'body' );
		 
		        var init_tooltip = function()
		        {
		            if( $( window ).width() < tooltip.outerWidth() * 1.5 ){
		                tooltip.css( 'max-width', $( window ).width() / 2 );
		            }
		            else {
		                tooltip.css( 'max-width', 340 );
					}	
		            var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
		                pos_top  = target.offset().top - tooltip.outerHeight() - 20;
		 
		            if( pos_left < 0 )
		            {
		                pos_left = target.offset().left + target.outerWidth() / 2 - 20;
		                tooltip.addClass( 'left' );
		            }
		            else {
		                tooltip.removeClass( 'left' );
					}	
		            if( pos_left + tooltip.outerWidth() > $( window ).width() )
		            {
		                pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
		                tooltip.addClass( 'right' );
		            }
		            else {
		                tooltip.removeClass( 'right' );
					}	
		            if( pos_top < 0 )
		            {
		                pos_top  = target.offset().top + target.outerHeight();
		                tooltip.addClass( 'top' );
		            }
		            else {
		                tooltip.removeClass( 'top' );
					}	
		            tooltip.css( { left: pos_left, top: pos_top } )
		                   .animate( { top: '+=10', opacity: 1 }, 50 );
		        };
		 
		        init_tooltip();
		        $( window ).resize( init_tooltip );
		 
		        var remove_tooltip = function()
		        {
		            tooltip.animate( { top: '-=10', opacity: 0 }, 50, function()
		            {
		                $( this ).remove();
		            });
		 
		            target.attr( 'title', tip );
		        };
		 
		        target.bind( 'mouseleave', remove_tooltip );
		        tooltip.bind( 'click', remove_tooltip );
		    });
		});
		$(document).ready(function() {
			$(window).keydown(function(event){
				if(event.keyCode === 13) {
					event.preventDefault();
					return false;
	    		}
  			});
		});		
		
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        		
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        $('.slider').slick({
	        
	        autoplay: false,
			autoplaySpeed: 2000,
	        
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }, 

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

